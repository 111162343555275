import React from "react"
import { Form, Card, Select, Row, Col, Button } from "antd"
import SortableItem from "./SortableItem"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import {
  DragOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons"

const ScoreAdjustment = ({ options }) => {
  const { Option } = Select

  const FormCard = ({ field, index, remove }) => (
    <Row gutter={[24, 24]}>
      <Col span={1}>
        <DragOutlined />
      </Col>
      <Col span={21}>
        <Form.Item
          {...field}
          name={field.name}
          label={index + 1}
          key={field.key}
          rules={[
            { required: true, message: "Please select a Additional Score" },
          ]}
        >
          <Select
            showSearch
            rules={[
              { required: true, message: "Please select a Additional Score" },
            ]}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.map(option => (
              <Option id={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={1}>
        <MinusCircleOutlined
          onClick={() => {
            remove(field.name)
          }}
        />
      </Col>
    </Row>
  )

  return (
    <Card title="Additional Score">
      <DndProvider backend={HTML5Backend}>
        <Form.List name="scoreAdjustmentDefinitions">
          {(fields, { add, remove, move }) => (
            <div>
              {fields.map((field, index) => (
                <SortableItem move={move} index={index}>
                  <FormCard field={field} index={index} remove={remove} />
                </SortableItem>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  block
                  onClick={() => {
                    add()
                  }}
                >
                  <PlusOutlined /> Add Additional Score
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>
      </DndProvider>
    </Card>
  )
}

export default ScoreAdjustment
