import React, { useEffect, useReducer } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import { PageHeader, Loading } from "lib"
import {
  CoreCompetencyDefinitionSetClient,
  CoreCompetencyClient,
  ScoreAdjustmentClient,
  DefaultApprovalPathsClient,
} from "@api"
import { SubmitForm } from "@components/configuartion/CoreSet"

const CoreCompetencySetConfiguration = ({ location }) => {
  const { state = null } = location
  const isCreate = !state?.id
  const initialState = {
    isLoading: false,
    data: null,
    options: {
      core: [],
      addition: [],
      defaultPath: [],
    },
    isLoad: false,
  }

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "initPage":
        return {
          ...state,
          data: {
            ...payload,
            coreCompetencyDefinitions: payload.coreCompetencyDefinitions
              .sort((a, b) => a.order - b.order)
              .map(item => item.coreCompetencyDefinitionId),
            scoreAdjustmentDefinitions: payload.scoreAdjustmentDefinitions
              .sort((a, b) => a.order - b.order)
              .map(item => item.scoreAdjustmentDefinitionId),
            file:
              payload.type === 2
                ? {
                    uid: 1,
                    name: payload.attachmentName,
                    url: `${process.env.REACT_APP_URL}/DownloadFile`,
                  }
                : null,
          },
          isLoading: false,
        }
      case "isLoading":
        return {
          ...state,
          isLoading: payload,
        }
      case "isLoad":
        return {
          ...state,
          isLoad: true,
        }
      case "options":
        return { ...state, options: payload }

      default:
        break
    }
  }

  const [{ isLoading, options, data }, dispatch] = useReducer(
    reducer,
    initialState
  )

  useEffect(() => {
    const fetchData = async id => {
      dispatch({ type: "isLoading", payload: true })
      const client = new CoreCompetencyDefinitionSetClient()
      const result = await client.get2(id).catch(err => {
        return null
      })
      dispatch({ type: "initPage", payload: result })
    }

    const fetchOptions = async () => {
      const coreClient = new CoreCompetencyClient()
      const scoreClient = new ScoreAdjustmentClient()
      const pathClient = new DefaultApprovalPathsClient()
      const coreOptions = await coreClient.get(0, 0, "name", true)
      const scoreOptions = await scoreClient.get(0, 0, "name", true)
      const pathOptions = await pathClient.getOptions("name", true)

      dispatch({
        type: "options",
        payload: {
          core: coreOptions.content.coreCompetencyGroup,
          addition: scoreOptions.content.scoreAdjustmentGroup,
          defaultPath: pathOptions,
        },
      })
    }

    if (!isCreate) fetchData(state?.id)
    fetchOptions()
  }, [isCreate, state?.id])

  return (
    <MainLayout>
      <Seo title="Core Set Configuration" />
      <PageHeader
        title={isCreate ? "Create Core Competency Set" : data?.name}
      />
      {!isLoading ? (
        <SubmitForm id={state?.id} options={options} data={data} />
      ) : (
        <Loading />
      )}
    </MainLayout>
  )
}

export default CoreCompetencySetConfiguration
