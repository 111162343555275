import React from "react"
import { Upload, Form, Button, Card, Divider } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import { normFile, DownloadConfigData } from "@action"
import { uploadProps } from "@object"
import { CoreCompetencyDefinitionSetClient } from "@api"

const AttachmentSession = ({ record = null }) => {
  const isCreate = !record
  const handleDownload = () => {
    DownloadConfigData(
      CoreCompetencyDefinitionSetClient,
      record.id,
      false,
      false
    )
  }

  return (
    <Card title="Frontline Template" style={{ margin: "20px 0px" }}>
      <Form.Item
        name="file"
        normalize={normFile}
        rules={[{ required: isCreate }]}
      >
        <Upload
          {...uploadProps}
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        >
          <Button icon={<UploadOutlined />}>
            {isCreate
              ? "Upload Frontline Template"
              : "Upload New Frontline Template"}
          </Button>
        </Upload>
      </Form.Item>
      {!isCreate ? (
        <div>
          <Divider orientation="left">Latest Uploaded Template</Divider>
          <Upload
            fileList={[record.file]}
            {...uploadProps}
            onPreview={handleDownload}
            isImageUrl={() => false}
            showUploadList={{
              showDownloadIcon: true,
              showRemoveIcon: false,
              showPreviewIcon: true,
            }}
          />
        </div>
      ) : null}
    </Card>
  )
}

export default AttachmentSession
