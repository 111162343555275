import React, { useState, useEffect } from "react"
import { Form, Row, Col, Button, Input, Checkbox, Select } from "antd"
import RatingScale from "./RatingScale"
import CoreCompetency from "./CoreCompetency"
import ScoreAdjustment from "./ScoreAdjustment"
import { CoreCompetencyDefinitionSetClient } from "@api"
import { showError, showSuccess } from "@action"
import { showSelectProp } from "@object"
import { navigate, Link } from "gatsby"
import { DeleteButton } from "lib"
import AttachmentSession from "./AttachmentSession"

const SubmitForm = ({ id, options, data }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { Option } = Select

  const onFinish = async values => {
    try {
      setIsLoading(true)
      const client = new CoreCompetencyDefinitionSetClient()
      if (!id) {
        const resultId = await client.create({ ...values, isEnabled: true })
        if (values.type === 2) await client.upload(resultId, values.file)
        showSuccess("Create Success!")
      } else {
        await client.update(id, {
          id: id,
          ...values,
        })
        if (values.type === 2 && !!values.file.data) {
          await client.upload(id, values.file)
        }
        showSuccess("Edit Success!")
      }
      navigate("/admin_panel/coreCompetencySet/")
    } catch (err) {
      showError(err)
      setIsLoading(false)
    }
  }
  const handleDelete = async () => {
    try {
      setIsLoading(true)
      const client = new CoreCompetencyDefinitionSetClient()
      await client.delete(id)
      showSuccess("Delete Success")
      navigate("/admin_panel/coreCompetencySet/")
    } catch (err) {
      showError(err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!!data) form.setFieldsValue(data)
  }, [data])

  const colProps = { span: 8 }
  return (
    <Form form={form} onFinish={onFinish}>
      <Row gutter={[12, 12]}>
        <Col {...colProps}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input name",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            label="Code"
            name="code"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input name",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            label="Template Type"
            name="type"
            rules={[
              {
                required: true,
                message: "Please select a Type",
              },
            ]}
          >
            <Select {...showSelectProp} disabled={!!id}>
              <Option value={1}>Managerial</Option>
              <Option value={0}>Non-Managerial</Option>
              <Option value={2}>Frontline</Option>
              <Option value={3}>Degree360</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item label="Default Approval Path" name="defaultApprovalPathId">
            <Select {...showSelectProp}>
              {options.defaultPath.map(option => (
                <Option value={option.id} key={option.id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            label="Enable"
            name="isEnabled"
            valuePropName="checked"
            rules={[{ required: true }]}
          >
            <Checkbox />
          </Form.Item>
        </Col>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.type !== currentValues.type
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("type") === 2 ? (
              <Col span={24}>
                <AttachmentSession record={data} />
              </Col>
            ) : (
              <Col span={24}>
                <RatingScale />
              </Col>
            )
          }
        </Form.Item>

        <Col span={24}>
          <CoreCompetency options={options.core} />
        </Col>
        <Col span={24}>
          <ScoreAdjustment options={options.addition} />
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="center">
        {data?.isCanDelete && (
          <Col>
            <DeleteButton
              disabled={isLoading}
              size="default"
              onClick={() => {
                handleDelete()
              }}
            />
          </Col>
        )}
        {(!id || data?.isCanDelete) && (
          <Col>
            <Button
              loading={isLoading}
              type="danger"
              shape="round"
              onClick={() => {
                form.submit()
              }}
            >
              {!!id ? "Save" : "Add"}
            </Button>
          </Col>
        )}
        <Col>
          <Link to="/admin_panel/coreCompetencySet/">
            <Button shape="round" disabled={isLoading}>
              Cancel
            </Button>
          </Link>
        </Col>
      </Row>
    </Form>
  )
}

export default SubmitForm
