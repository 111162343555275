import React, { useRef } from "react"
import { Form, Card } from "antd"
import JoditEditor from "jodit-react"
import { editorConfig } from "@action"

const RatingScale = () => {
  const rateEditor = useRef(null)
  return (
    <Card title="Rating Scale">
      {typeof window !== "undefined" ? (
        <Form.Item name="ratingScale">
          <JoditEditor ref={rateEditor} config={editorConfig} tabIndex={0} />
        </Form.Item>
      ) : null}
    </Card>
  )
}

export default RatingScale
